import { Component, OnInit } from '@angular/core';
import { SideBarList } from '../../constants';
import { Router } from '@angular/router';
import { SharedModule } from '../../shared.module';
import { SideBarItem, SubMenu } from '../../types';

@Component({
  selector: 'app-side-bar',
  imports: [SharedModule],
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  sideBar: any[] = SideBarList;
  activeTab: string = 'Dashboard';

  constructor(private router: Router) {}

  ngOnInit(): void {
    const routeName = this.getCurrentRouteName();
    this.updateActiveTab(routeName);
  }

  getCurrentRouteName(): string {
    return location.pathname.split('/')[1];
  }

  updateActiveTab(routeName: string): void {
    const currentMenu = this.sideBar.find((menu) => menu.key === routeName);
    this.activeTab = currentMenu ? currentMenu.label : 'Dashboard';
  }

  navigateTo(route: string, label: string): void {
    this.activeTab = label;
    this.router.navigate([route]);
  }
}
