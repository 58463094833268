import { Component } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { TableCols } from '../../../types';
import { AUDIT_ACTIVITY_COLS } from '../../../constants';
import { ProjectService } from '../../project-management/project.services';
import { NavigationExtras, Router } from '@angular/router';
import { AuditService } from '../audit.service';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-activities-audit',
  templateUrl: './activities.component.html',
  styleUrl: './activities.component.scss',
  imports: [SharedModule],
  providers: [MessageService],
})
export class ActivitiesAuditComponent {
  isLoading: boolean = true;
  activitiesList: any[] = [];
  skeletonRows = new Array(10);
  cols: TableCols[] = AUDIT_ACTIVITY_COLS;
  jobData: any;
  onHover: number = 0;

  constructor(
    private router: Router,
    private service: ProjectService,
    private auditService: AuditService,
    private messageService: MessageService,
    private http: HttpClient
  ) {
    this.jobData = history.state.jobData;
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  async ngOnInit() {
    this.activitiesList = await this.getActivities();
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  async generatePdf(rowData: any): Promise<void> {
    try {
      const data = await this.auditService.generateAuditPdf({
        jobActivityId: rowData.id,
      });
      if (data) {
        this.successToast('PDF Generated Successfully');
      }
    } catch (error: any) {
      this.errorToast(error.messageService);
    }
  }

  async downloadPdf(rowData: any): Promise<any> {
    try {
      const response: any = await this.http
        .get(
          `${environment.baseUrl}/api/audit/get-pdf?jobActivityId=${rowData.id}`,
          {
            responseType: 'blob',
          }
        )
        .toPromise();

      const file = new Blob([response], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(file);
      link.download = `${rowData.schemeActivityName}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  audit(activitiesData: any): any {
    const params: NavigationExtras = {
      state: { activitiesData, jobData: this.jobData },
    };
    this.router.navigate([`audit/${activitiesData.id}`], params);
  }

  navigateBack(): void {
    window.history.back();
  }

  async getActivities(): Promise<any[]> {
    try {
      const res = await this.service.fetchJobActivitiesData(this.jobData.id);
      if (res) this.isLoading = false;
      return res;
    } catch (error: any) {
      this.isLoading = false;
      return [];
    }
  }
}
