<div class="page-container schedule-container">
  <form class="schedule-header" [formGroup]="formData">
    <div style="width: 15%">
      <app-dropdown
        [options]="AgentList"
        [control]="formData.get('agent')"
        label="Agent"
        placeholder="Select"
        optionLabel="name"
      />
    </div>
    <div style="width: 15%">
      <label class="input-label">Date Range</label>
      <p-datepicker
        formControlName="dateRange"
        selectionMode="range"
        [readonlyInput]="true"
        [appendTo]="'body'"
        dateFormat="yy-mm-dd"
      />
    </div>
    <app-button
      label="Filter"
      (clickEvent)="filterSchedule()"
      [disabled]="formData.invalid"
    />
  </form>

  <div class="schedule-table">
    <div>
      <div class="header"></div>
      <div class="days-container" *ngFor="let schedule of scheduleData">
        {{ schedule.date }}
      </div>
    </div>
    <div class="schedule-timeline-table-container">
      <table>
        <tr class="header-row">
          <th *ngFor="let hour of hoursPerDay" class="each-header">
            {{ hour.label }}
          </th>
        </tr>

        <tr class="schedule-timeline-row" *ngFor="let data of scheduleData">
          <td *ngFor="let hour of hoursPerDay" class="each-slots">
            <ng-container *ngIf="hasScheduleAt(hour.hour, data.schedule)">
              <div
                class="slot-helper-container"
                *ngFor="let slot of hasScheduleAt(hour.hour, data.schedule)"
                [ngStyle]="
                  calculateSlotStyle(slot.fromTime, slot.toTime, hour.hour)
                "
                (click)="onReschedule()"
              >
                <h6 style="margin-bottom: 0.5rem">
                  {{ slot.project }}
                </h6>
                <h1 style="display: flex; align-items: center; gap: 0.5rem">
                  <i class="pi pi-history"></i>
                  {{ slot.jobName }} - {{ slot.type }}
                </h1>

                <div class="location">
                  <img src="assets/location.icon.svg" />
                  {{ getAddress(slot.address) }}
                </div>
                <div style="font-size: 0.9rem">
                  {{ fromTime }} - {{ toTime }}
                </div>
              </div>
            </ng-container>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <p-dialog
    header="Header"
    [(visible)]="display"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header">
      <h3>Re-Schedule</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form class="dialog-form" [formGroup]="dialogForm">
        <app-calendar label="Date" [control]="dialogForm.get('date')" />
        <div class="grid-container">
          <div>
            <label class="input-label">From Time</label>
            <p-datepicker
              inputId="calendar-12h"
              formControlName="fromTime"
              [timeOnly]="true"
              hourFormat="12"
              [appendTo]="'body'"
            />
          </div>
          <div>
            <label class="input-label">To Time</label>
            <p-datepicker
              inputId="calendar-12h"
              formControlName="ToTime"
              [timeOnly]="true"
              hourFormat="12"
              [appendTo]="'body'"
            />
          </div>
        </div>
        <div style="margin-top: 1rem">
          <label class="input-label">
            Notes
            <!-- <span class="required-label">*</span> -->
          </label>
          <textarea
            rows="2"
            cols="30"
            pInputTextarea
            formControlName="notes"
            class="textarea"
          ></textarea>
          <div
            class="error-message"
            *ngIf="
              formData.get('notes')?.invalid && formData.get('notes')?.touched
            "
          >
            Notes is required.
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button label="Save" (clickEvent)="onSave()" />
    </ng-template>
  </p-dialog>
</div>
