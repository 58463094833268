import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ChartModule } from 'primeng/chart';
import { Certificate, ColorMap, DropDownType } from '../../../types';
import moment from 'moment';
import { DashboardService } from '../dashboard.service';
import { CERTIFICATE_VALUE } from '../../../constants';
import { SharedModule } from '../../../shared.module';

@Component({
  selector: 'app-certificate-count',
  imports: [SharedModule, ChartModule],
  providers: [MessageService],
  templateUrl: './certificate-count.component.html',
  styleUrl: './certificate-count.component.scss',
})
export class CertificateCountComponent {
  data: any;
  options: any;
  CertificateList: DropDownType[] = CERTIFICATE_VALUE;
  certificateData: any[] = [];

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  constructor(
    private messageService: MessageService,
    private service: DashboardService
  ) {}

  async ngOnInit() {
    await this.getCertificateCounts();
    this.initializeChartOptions();
    this.updateChartData();
  }

  async getCertificateCounts(): Promise<void> {
    try {
      const data = await this.service.fetchCertificateCountData();
      if (data) {
        this.certificateData = data;
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  updateChartData() {
    const documentStyle = getComputedStyle(document.documentElement);
    const colorMap: ColorMap = {
      STC: '--blue-500',
      VEEC: '--yellow-500',
      ESC: '--green-500',
      LCG: '--brown-500',
      ACCU: '--pink-100',
      PRC: '--pink-500',
    };

    const formattedLabel = this.labelFormat();

    const groupedData = formattedLabel.map((month) => {
      return {
        month: month,
        values: this.CertificateList.map((type) => {
          const record = this.certificateData.find(
            (item) =>
              item.month === month && item.certificateName === type.value
          );
          return record ? record.certificateValue : 0;
        }),
      };
    });

    const datasets = this.CertificateList.map((type, index) => {
      return {
        label: type.value,
        data: groupedData.map((item) => item.values[index]),
        fill: false,
        borderColor: documentStyle.getPropertyValue(colorMap[type.value]),
        tension: 0.1,
      };
    });

    this.data = {
      labels: formattedLabel,
      datasets: datasets,
    };
  }

  private labelFormat(): string[] {
    return Array.from({ length: 6 }, (_, i) =>
      moment()
        .subtract(5 - i, 'months')
        .format('MMMM')
    );
  }

  initializeChartOptions() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--p-text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--p-text-muted-color'
    );
    const surfaceBorder = documentStyle.getPropertyValue(
      '--p-content-border-color'
    );

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: { color: textColor },
        },
      },
      scales: {
        x: {
          ticks: {
            source: 'auto',
            maxTicksLimit: 6,
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder || '#ccc',
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
          },
        },
        y: {
          ticks: { color: textColorSecondary },
          grid: {
            color: surfaceBorder || '#ccc',
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
          },
          beginAtZero: true,
        },
      },
    };
  }
}
