import { Component } from '@angular/core';
import { ButtonComponent } from '../../../Components/button/button.component';
import { SharedModule } from '../../../shared.module';
import {
  DataItem,
  DwellingType,
  JobListType,
  ProjectListType,
  TableCols,
} from '../../../types';
import {
  DOCUMENT_VERSION,
  DWELLING_TYPE,
  FORMS_COLS,
  JOB_COLS,
} from '../../../constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InputComponent } from '../../../Components/input/input.component';
import { DropdownComponent } from '../../../Components/dropdown/dropdown.component';
import { NavigationExtras, Router } from '@angular/router';
import ROUTES from '../../../routes.const';
import { ProjectService } from '../project.services';
import { MessageService } from 'primeng/api';
import { AzureMapComponent } from '../azure-map/azure-map.component';
import { trimFormData } from '../../common-methods';
import { DisabledInputComponent } from '../../../Components/disabled-input/disabled-input.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import JSZip from 'jszip';
import { JobsMapComponent } from '../jobs-map/jobs-map.component';
import { SearchFieldComponent } from '../../../Components/searchField/search-text.component';
import { environment } from '../../../../environments/environment';
import { ManageFieldTypeComponent } from '../job-details/manage-field-type/manage-field-type.component';
import { DomSanitizer } from '@angular/platform-browser';
import { unzip } from 'fflate';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrl: './project-details.component.scss',
  providers: [MessageService],
  imports: [
    ButtonComponent,
    SharedModule,
    InputComponent,
    DropdownComponent,
    AzureMapComponent,
    DisabledInputComponent,
    JobsMapComponent,
    SearchFieldComponent,
    ManageFieldTypeComponent,
    ButtonModule,
  ],
})
export class ProjectDetailsComponent {
  projectData!: ProjectListType;
  display: boolean = false;
  cols: TableCols[] = JOB_COLS;
  FormsCols: TableCols[] = FORMS_COLS;
  JobList: JobListType[] = [];
  formData!: FormGroup;
  DwellingType: DwellingType[] = DWELLING_TYPE;
  isLoading: boolean = true;
  onSaveLoad: boolean = false;
  onHover: number = 0;
  rowData!: JobListType;
  deleteDialog: boolean = false;
  editJob: boolean = false;
  jobDataClone: any[] = [];
  jobsOnMap!: any[];
  fullAddress: any;
  isLoadingFields: boolean = false;
  projectFields: any[] = [];
  documentVersionCol: TableCols[] = DOCUMENT_VERSION;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private service: ProjectService,
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    this.initializeForm();
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  async onDownloadForm(data: any): Promise<void> {
    await this.fetchForms(data);
  }

  private async fetchForms(data: any): Promise<any> {
    const headers = new HttpHeaders({
      Accept: 'application/zip',
    });
    const payload = { ImagePaths: [data] };
    try {
      const response: any = await this.http
        .post(`${environment.baseUrl}/api/images/fetch`, payload, {
          headers,
          responseType: 'arraybuffer',
        })
        .toPromise();

      const files: Record<string, Uint8Array> = await new Promise(
        (resolve, reject) => {
          unzip(new Uint8Array(response), (err, unzippedFiles) => {
            if (err) {
              reject(err);
            } else {
              resolve(unzippedFiles);
            }
          });
        }
      );

      const imageUrls = Object.keys(files).map((filename) => {
        const blob = new Blob([files[filename]]);
        const url = URL.createObjectURL(blob);
        this.downloadFile(url, filename);
        return url;
      });
      return imageUrls;
    } catch (error: any) {
      this.isLoading = false;
      this.errorToast(error.message);
    }
  }

  private downloadFile(url: string, filename: string): void {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }

  initializeForm(): void {
    this.formData = this.formBuilder.group({
      jobName: ['', Validators.required],
      locationName: ['', Validators.required],
      preciseLocation: ['', Validators.required],
      resolvedZone: ['', Validators.required],
      dwellingType: ['', Validators.required],
      resolvedAddress: ['', Validators.required],
    });
  }

  async handleSearch(searchVal: string): Promise<void> {
    this.JobList = this.jobDataClone.filter((item) =>
      item.jobName.toLowerCase().includes(searchVal.trim().toLowerCase())
    );
  }

  async ngOnInit(): Promise<void> {
    this.projectData = history.state.rowData;
    // this.JobList = this.projectData.jobs;
    this.JobList = await this.getJobData();
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'NEW':
        return 'tag-new';
      case 'ACTIVE':
        return 'tag-active';
      case 'ASSESSMENTCOMPLETE':
        return 'tag-assessment-complete';
      case 'INSTALLATION':
        return 'tag-installation';
      case 'AUDIT':
        return 'tag-audit';
      case 'Audit_Aggregator':
        return 'tag-audit-aggregator';
      case 'SUBMITTED':
        return 'tag-submitted';
      case 'ASSESSMENT':
        return 'tag-job-assessment';
      case 'AUDITPASS':
        return 'tag-submitted';
      case 'AUDITFAIL':
        return 'tag-paid';
      case 'SUBMISSIONFAILED':
        return 'tag-paid';
      default:
        return '';
    }
  }

  async getJobData(): Promise<any[]> {
    try {
      const data = await this.service.getByProjectId(this.projectData.id);

      const response = data.jobFields.filter(
        (item: any) => item.field.fieldType !== 'Constant'
      );

      const photoValuesWithId = this.extractPhotoValues(response);

      if (photoValuesWithId) this.isLoadingFields = false;

      const dropdownData = this.getFieldValueDropdown(response);

      const updatedData = this.updateDataWithImages(
        dropdownData,
        photoValuesWithId
      );

      this.jobDataClone = data.jobs;
      this.jobsOnMap = data.jobs;
      this.isLoading = false;
      this.projectData = data;
      this.projectFields = updatedData;

      return data.jobs;
    } catch (error: any) {
      this.errorToast(error.message);
      return [];
    }
  }

  private getFieldValueDropdown(res: any) {
    const modifiedData = res.map((item: any) => {
      let fieldDropdown = [];

      if (
        item.field &&
        item.field.fieldValues &&
        item.field.fieldValues.length > 0
      ) {
        fieldDropdown = JSON.parse(item.field.fieldValues.at(-1).values);
      }

      return {
        ...item,
        fieldDropdown,
      };
    });

    return modifiedData;
  }

  private extractPhotoValues(res: any[]): { id: number; images: any }[] {
    const data = res
      .filter(
        (item) =>
          item.field.fieldType === 'Photo' ||
          item.field.fieldType === 'Signature'
      )
      .map((item) => {
        return {
          id: item.id,
          images: this.handleImages(item.jobFieldValues.at(-1)?.files),
        };
      });
    return data;
  }

  handleImages(res: any): any[] {
    const fields = res;
    const safeUrls: any[] = [];

    for (const filePath in fields) {
      if (fields.hasOwnProperty(filePath)) {
        const base64Data = fields[filePath];
        const safeUrl = this.convertImage(base64Data);
        if (safeUrl) {
          safeUrls.push({ imageBinary: safeUrl, path: filePath });
        }
      }
    }

    return safeUrls;
  }

  convertImage(base64Data: string): any {
    try {
      if (!base64Data || base64Data.trim() === '') {
        return null;
      }
      // Detect the image type based on the base64 string prefix
      let imageType: string;

      if (base64Data.startsWith('/9j/')) {
        imageType = 'image/jpeg';
      } else if (base64Data.startsWith('iVBORw0KGgo')) {
        imageType = 'image/png';
      } else if (base64Data.startsWith('R0lGODlh')) {
        imageType = 'image/gif';
      } else if (base64Data.startsWith('<svg')) {
        imageType = 'image/svg+xml';
      } else {
        return null;
      }

      const base64Image = `data:${imageType};base64,${base64Data}`;
      return this.sanitizer.bypassSecurityTrustUrl(base64Image); // Safe URL for the image
    } catch (error) {
      return null;
    }
  }

  private updateDataWithImages(res: any[], imagesData: any[]): DataItem[] {
    return res.map((item) => {
      let metaData: any = '';

      if (item.jobFieldValues[0]?.metadata) {
        const decodedString = item.jobFieldValues[0]?.metadata;

        try {
          const jsonArray = JSON.parse(decodedString);
          const parsedData = jsonArray.map((item: any) => JSON.parse(item));

          metaData = parsedData.map((c: any) => {
            // Extract latitude, longitude, and direction references
            const latitude = c['GPS Latitude'] ?? '';
            const latitudeRef = c['GPS Latitude Ref'] ?? '';
            const longitude = c['GPS Longitude'] ?? '';
            const longitudeRef = c['GPS Longitude Ref'] ?? '';

            // Format latitude and longitude with direction indicators
            const formattedLatitude = latitude
              ? `${latitude} ${latitudeRef}`
              : '';
            const formattedLongitude = longitude
              ? `${longitude} ${longitudeRef}`
              : '';

            return {
              GPSLatitude: formattedLatitude,
              GPSLongitude: formattedLongitude,
              DateTime: c['Date/Time'] ?? '',
            };
          });
        } catch (error) {
          console.error('Error parsing metadata:', error);
        }
      }

      const imageGroup = imagesData.find((group) => group.id === item.id);
      if (imageGroup) {
        return { ...item, value: imageGroup?.images, metaData };
      } else {
        return {
          ...item,
          value:
            item.field.fieldType === 'Constant'
              ? item.fieldDropdown
              : item.jobFieldValues.at(-1)?.value || null,
        };
      }
    });
  }

  handleAddJob(): void {
    this.display = true;
    this.editJob = false;
    this.formData.patchValue({
      jobName: '',
      locationName: '',
      preciseLocation: '',
      resolvedZone: '',
      resolvedAddress: '',
      dwellingType: this.projectData.type === 2 ? 'Commercial' : 'Residential',
    });
  }

  closeDialog(): void {
    this.display = false;
    this.deleteDialog = false;
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        ...this.formData.value,
        projectId: this.projectData.id,
        resolvedAddress: this.fullAddress,
      };
      const res = await this.service.createJobData(trimFormData(payload));
      if (res) {
        this.display = false;
        this.JobList = await this.getJobData();
        this.onSaveLoad = false;
        await this.getJobData();
        this.successToast('Job Created Successfully');
        window.location.reload();
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.error.message);
    }
  }

  jobDetails(jobData: JobListType): any {
    const params: NavigationExtras = {
      state: { jobData, projectData: this.projectData },
    };
    this.router.navigate([ROUTES.JOB_DETAILS], params);
  }

  navigateBack(): void {
    window.history.back();
  }

  handleEdit(rowData: JobListType): void {
    this.display = true;
    this.editJob = true;
    this.rowData = rowData;
    this.formData.patchValue({
      ...rowData,
      resolvedAddress: rowData.resolvedAddress.freeformAddress,
    });
  }

  handleDelete(rowData: JobListType): void {
    this.deleteDialog = true;
    this.rowData = rowData;
  }

  async onDeleteJob(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const res = await this.service.deleteJobData(this.rowData.id);
      if (res) {
        this.deleteDialog = false;
        this.onSaveLoad = false;
        this.JobList = await this.getJobData();
        this.successToast('Job Deleted Successfully');
        window.location.reload();
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async onUpdate(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        ...this.formData.value,
        id: this.rowData.id,
        projectId: this.projectData.id,
        resolvedAddress: this.fullAddress,
      };
      const res = await this.service.updateJobData(trimFormData(payload));
      if (res) {
        this.display = false;
        this.JobList = await this.getJobData();
        this.onSaveLoad = false;
        await this.getJobData();
        this.successToast('Job Updated Successfully');
        window.location.reload();
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async getAddress(data: {
    address: any;
    postalCode: number;
    coordinates: string;
  }): Promise<void> {
    this.prefillZoneName(data.postalCode);
    this.fullAddress = data.address;

    this.formData.patchValue({
      resolvedAddress: data.address.freeformAddress,
      preciseLocation: data.coordinates,
    });
  }

  private async prefillZoneName(val: number): Promise<string> {
    try {
      const res = await this.service.getZoneName(val);
      this.formData.patchValue({
        resolvedZone: res,
      });
      return res;
    } catch (error: any) {
      return '';
    }
  }
}
