import { Component } from '@angular/core';
import { ButtonComponent } from '../../Components/button/button.component';
import { SharedModule } from '../../shared.module';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropDownType, TableCols } from '../../types';
import { InputComponent } from '../../Components/input/input.component';
import { DropdownComponent } from '../../Components/dropdown/dropdown.component';
import { CATEGORIES, DEVICE_TYPE, TASK_COLS } from '../../constants';
import { CatalogueService } from '../catalogue-management/catalogue.service';
import { TaskService } from './task-management.service';

@Component({
  selector: 'app-task-management',
  providers: [MessageService],
  imports: [ButtonComponent, SharedModule],
  templateUrl: './task-management.component.html',
  styleUrl: './task-management.component.scss',
})
export class TaskManagementComponent {
  installerTaskList: any[] = [];
  taskList: any[] = [];
  isLoading: boolean = true;
  formData!: FormGroup;
  skeletonRows = new Array(10);
  onHover: number = 0;
  cols: TableCols[] = TASK_COLS;
  taskDialog: boolean = false;
  onSaveLoad: boolean = false;
  deleteDialog: boolean = false;
  edit: boolean = false;
  deviceType: DropDownType[] = DEVICE_TYPE;
  categories: DropDownType[] = CATEGORIES;
  serviceCatalogueList: any[] = [];
  rowData: any;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private service: CatalogueService,
    private taskService: TaskService
  ) {
    this.formData = this.formBuilder.group({
      taskId: ['', Validators.required],
      serviceCatalogueIds: ['', Validators.required],
    });
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  async ngOnInit(): Promise<void> {
    this.installerTaskList = await this.getInstallerTaskData();
    this.serviceCatalogueList = await this.getServiceData();
    // this.taskList = await this.getTaskData();
  }

  async getInstallerTaskData(): Promise<any[]> {
    try {
      const data = await this.taskService.fetchInstallerTaskData();
      if (data) this.isLoading = false;
      return data;
    } catch (error: any) {
      this.isLoading = false;
      this.errorToast(error.message);
      return [];
    }
  }

  async getServiceData(): Promise<any[]> {
    try {
      const data = await this.service.fetchServiceCatalogueData();
      return data;
    } catch (error: any) {
      this.errorToast(error.message);
      return [];
    }
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  async handleAddDialog(): Promise<void> {
    this.formData.reset();
    this.edit = false;
    this.taskDialog = true;
  }

  closeDialog(): void {
    this.taskDialog = false;
  }

  handleEdit(rowData: any): void {
    this.taskDialog = true;
    this.formData.patchValue(rowData);
    this.rowData = rowData;
    this.edit = true;
  }

  handleDelete(rowData: any): void {
    this.rowData = rowData;
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        taskId: this.rowData.taskId,
        serviceCatalogueIds: this.formData.value.serviceCatalogueIds,
      };
      const data = await this.taskService.createInstallerTaskData(payload);
      if (data) {
        this.onSaveLoad = false;
        this.taskDialog = false;
        this.installerTaskList = await this.getInstallerTaskData();
        this.successToast('Task Created Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onUpdate(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        ...this.formData.value,
      };
      const data = await this.taskService.updateInstallerTaskData(payload);
      if (data) {
        this.onSaveLoad = false;
        this.taskDialog = false;
        this.installerTaskList = await this.getInstallerTaskData();
        this.successToast('Task Updated Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }
}
