<div class="certificate-container">
  <div style="display: flex; justify-content: space-between">
    <div class="heading">Certificate Values</div>
    <div style="width: 10%">
      <p-select
        [options]="CertificateList"
        [(ngModel)]="certificateType"
        optionLabel="label"
        optionValue="label"
        placeholder="Select"
        (onChange)="onCertificateTypeChange($event)"
      />
    </div>
  </div>
  <p-chart type="line" [data]="price" [options]="options" />
</div>

