<div class="page-container audit-container">
  <p-toast />
  <p-table
    [columns]="cols"
    [value]="ProjectList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr *ngFor="let jobs of rowData.jobs; let isFirstRow = first">
        <td *ngIf="isFirstRow" [attr.rowspan]="rowData.jobs.length">
          {{ rowData.name }}
        </td>
        <td class="job-name" (click)="audit(jobs, rowData)">
          {{ jobs.jobName }}
        </td>
        <td>{{ jobs.locationName }}</td>
        <td>{{ jobs.dwellingType }}</td>
        <td style="width: 15%">
          <div [ngClass]="getStatusClass(jobs.status)">
            {{ jobs.status }}
          </div>
        </td>
        <!-- <td style="width: 5%">
          <div (click)="op.toggle($event)">
            <img
              *ngIf="jobs.status === 'AUDITPASS'"
              src="assets/ActionMenu.svg"
              style="cursor: pointer"
            />
            <p-popover #op>
              <ng-template pTemplate="content">
                <div
                  (mouseover)="handleHover(1)"
                  (click)="!onGeneratePdf && generatePdf(jobs)"
                  class="list-item"
                  [class.hover-bg-light-blue]="onHover === 1"
                  (mouseleave)="onHover = 0"
                  [class.disabled]="onGeneratePdf"
                >
                  <i
                    *ngIf="!onGeneratePdf"
                    class="pi pi-file-export"
                    style="font-size: 1.3rem"
                  ></i>
                  <i
                    *ngIf="onGeneratePdf"
                    class="pi pi-spinner pi-spin"
                    style="font-size: 1.3rem"
                  ></i>
                  <span style="font-size: 14px">
                    {{ onGeneratePdf ? "Loading..." : "Generate PDF" }}
                  </span>
                </div>
                <div
                  (mouseover)="handleHover(2)"
                  (click)="!onDownloadPdf && downloadPdf(jobs)"
                  class="list-item"
                  [class.hover-bg-light-green]="onHover === 2"
                  (mouseleave)="onHover = 0"
                  [class.disabled]="onDownloadPdf"
                >
                  <i
                    *ngIf="!onDownloadPdf"
                    class="pi pi-file-pdf"
                    style="font-size: 1.3rem"
                  ></i>
                  <i
                    *ngIf="onDownloadPdf"
                    class="pi pi-spinner pi-spin"
                    style="font-size: 1.3rem"
                  ></i>
                  <span style="font-size: 14px">
                    {{ onDownloadPdf ? "Loading..." : "Download PDF" }}
                  </span>
                </div>
              </ng-template>
            </p-popover>
          </div>
        </td> -->
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" *ngIf="ProjectList.length === 0">
      <tr *ngIf="!isLoading">
        <td colspan="6">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
