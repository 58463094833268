<p-toast />
<div class="field-type">
  <div class="heading">{{ name }}</div>

  <p-table [columns]="cols" [value]="data">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>

      <ng-container *ngIf="isLoadingField">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td>
          <div style="display: flex; gap: 1rem">
            <div style="font-size: 0.9rem">
              {{ rowData.field.label }}
            </div>

            <div style="padding-top: 0.1rem">
              <i
                class="pi pi-info-circle"
                [pTooltip]="tooltipContent"
                tooltipPosition="bottom"
              ></i>
              <ng-template #tooltipContent>
                <div class="tool-tip">
                  <div style="padding: 0.5rem">
                    <h1>
                      {{ rowData.field?.description }}
                    </h1>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </td>

        <td style="width: 6%">
          <div
            style="font-size: 0.9rem"
            *ngIf="
              rowData.field.fieldType !== 'Photo' &&
              rowData.field.fieldType !== 'DE_Picklist' &&
              rowData.field.fieldType !== 'Signature'
            "
          >
            {{ rowData.value || "None" }}
          </div>
          <div
            *ngIf="
              rowData.field.fieldType === 'Photo' ||
              rowData.field.fieldType === 'Signature'
            "
          >
            <div
              *ngFor="let image of rowData.value"
              style="position: relative; display: inline-block; margin: 5px"
            >
              <div style="cursor: pointer" (click)="displayImage(image.path)">
                <p-image
                  *ngIf="rowData.value"
                  [src]="image.imageBinary"
                  alt=""
                  width="40px"
                  height="30px"
                  [preview]="false"
                ></p-image>
              </div>

              <i
                class="pi pi-times"
                (click)="onRemoveImage(rowData.id, image.path)"
                style="
                  position: absolute;
                  top: -5px;
                  right: -5px;
                  cursor: pointer;
                  color: red;
                  font-size: 0.6rem;
                  background-color: rgb(240, 228, 228);
                  border-radius: 50%;
                  padding: 2px;
                "
              ></i>
            </div>
            <div style="font-size: 0.9rem" *ngIf="!rowData.value">None</div>
          </div>
          <div *ngIf="rowData.field.fieldType === 'DE_Picklist'">
            <div style="font-size: 0.9rem" *ngIf="rowData.value">
              {{ rowData.value }}
            </div>
            <div style="font-size: 0.9rem" *ngIf="!rowData.value">None</div>
          </div>
        </td>
        <td style="width: 20%">
          <div
            *ngIf="rowData.field.fieldType === 'Photo'"
            style="display: flex; gap: 0.5rem; flex-direction: column"
          >
            <div
              style="font-size: 0.8rem"
              *ngFor="let data of rowData.metaData"
            >
              <div
                style="font-size: 0.8rem; display: flex"
                *ngIf="data.GPSLatitude"
              >
                {{ data.GPSLatitude }} - {{ data.GPSLongitude }}
              </div>
              {{ data.DateTime }}
            </div>
          </div>
        </td>

        <td style="width: 15%" *ngIf="name === 'Assessment'">
          <div
            *ngIf="
              jobData !== 'ASSESSMENTCOMPLETE' &&
              jobData !== 'AUDIT' &&
              jobData !== 'INSTALLATION' &&
              jobData !== 'AUDITPASS'
            "
          >
            <div *ngIf="rowData.field.fieldType === 'DE_Numeric'">
              <p-inputNumber
                [(ngModel)]="rowData.value"
                [useGrouping]="false"
                mode="decimal"
                [minFractionDigits]="0"
                [maxFractionDigits]="3"
              />
            </div>

            <div *ngIf="rowData.field.fieldType === 'DE_Picklist'">
              <p-select
                [options]="rowData.fieldDropdown"
                [(ngModel)]="rowData.value"
                placeholder="Select"
                [appendTo]="'body'"
                optionLabel="key"
                optionValue="value"
              />
            </div>

            <div *ngIf="rowData.field.fieldType === 'DE_Placeholder'">
              <input pInputText [(ngModel)]="rowData.value" class="inputtext" />
            </div>

            <div
              *ngIf="rowData.field.fieldType === 'Boolean'"
              style="display: flex; gap: 2rem"
            >
              <div *ngFor="let data of Boolean">
                <p-radioButton
                  [inputId]="data.key"
                  [value]="data.name"
                  [(ngModel)]="rowData.value"
                />
                <label [for]="data.key" class="ml-2">
                  {{ data.name }}
                </label>
              </div>
            </div>

            <div *ngIf="rowData.field.fieldType === 'Photo'">
              <label htmlFor="fileType">
                <div class="upload-photo">Upload Photo</div>
                <input
                  type="file"
                  (change)="onFileSelected($event, rowData)"
                  id="fileType"
                  style="display: none"
                  multiple
                />
              </label>
            </div>
          </div>
        </td>

        <td
          style="width: 15%"
          *ngIf="name === 'Installation' || name === 'Project Fields'"
        >
          <div
            *ngIf="
              projectStatus !== 'COMPLETED' &&
              projectStatus !== 'INVOICE' &&
              jobData !== 'AUDIT' &&
              jobData !== 'AUDITPASS'
            "
          >
            <div *ngIf="rowData.field.fieldType === 'DE_Numeric'">
              <p-inputNumber
                [(ngModel)]="rowData.value"
                [useGrouping]="false"
                mode="decimal"
                [minFractionDigits]="0"
                [maxFractionDigits]="3"
              />
            </div>

            <div *ngIf="rowData.field.fieldType === 'DE_Picklist'">
              <p-select
                [options]="rowData.fieldDropdown"
                [(ngModel)]="rowData.value"
                placeholder="Select"
                [appendTo]="'body'"
                optionLabel="key"
                optionValue="value"
              />
            </div>

            <div *ngIf="rowData.field.fieldType === 'DE_Placeholder'">
              <input pInputText [(ngModel)]="rowData.value" class="inputtext" />
            </div>

            <div
              *ngIf="rowData.field.fieldType === 'Boolean'"
              style="display: flex; gap: 2rem"
            >
              <div *ngFor="let data of Boolean">
                <p-radioButton
                  [inputId]="data.key"
                  [value]="data.name"
                  [(ngModel)]="rowData.value"
                />
                <label [for]="data.key" class="ml-2">
                  {{ data.name }}
                </label>
              </div>
            </div>

            <div *ngIf="rowData.field.fieldType === 'Photo'">
              <label htmlFor="fileType">
                <div class="upload-photo">Upload Photo</div>
                <input
                  type="file"
                  (change)="onFileSelected($event, rowData)"
                  id="fileType"
                  style="display: none"
                  multiple
                />
              </label>
            </div>
          </div>
        </td>

        <td style="width: 15%" *ngIf="name === 'Form Fields'">
          <div>
            <div *ngIf="rowData.field.fieldType === 'DE_Numeric'">
              <p-inputNumber
                [(ngModel)]="rowData.value"
                [useGrouping]="false"
                mode="decimal"
                [minFractionDigits]="0"
                [maxFractionDigits]="3"
              />
            </div>

            <div *ngIf="rowData.field.fieldType === 'DE_Picklist'">
              <p-select
                [options]="rowData.fieldDropdown"
                [(ngModel)]="rowData.value"
                placeholder="Select"
                [appendTo]="'body'"
                optionLabel="key"
                optionValue="value"
              />
            </div>

            <div *ngIf="rowData.field.fieldType === 'DE_Placeholder'">
              <input pInputText [(ngModel)]="rowData.value" class="inputtext" />
            </div>

            <div
              *ngIf="rowData.field.fieldType === 'Boolean'"
              style="display: flex; gap: 2rem"
            >
              <div *ngFor="let data of Boolean">
                <p-radioButton
                  [inputId]="data.key"
                  [value]="data.name"
                  [(ngModel)]="rowData.value"
                />
                <label [for]="data.key" class="ml-2">
                  {{ data.name }}
                </label>
              </div>
            </div>

            <div *ngIf="rowData.field.fieldType === 'Photo'">
              <label htmlFor="fileType">
                <div class="upload-photo">Upload Photo</div>
                <input
                  type="file"
                  (change)="onFileSelected($event, rowData)"
                  id="fileType"
                  style="display: none"
                  multiple
                />
              </label>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" *ngIf="data.length === 0">
      <tr *ngIf="!isLoadingField">
        <td colspan="5">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>

  <!-- Image Preview Dialog -->
  <p-dialog
    header="Header"
    [(visible)]="imageDialog"
    [modal]="true"
    [closable]="false"
  >
    <ng-template pTemplate="header">
      <h3>Image Preview</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <div
        *ngIf="isLoading && !originalImageUrl"
        style="display: flex; justify-content: center"
      >
        <i class="pi pi-spinner pi-spin" style="font-size: 2rem"></i>
      </div>
      <img
        *ngIf="originalImageUrl"
        [src]="originalImageUrl"
        alt=""
        style="max-height: 600px"
      />
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Close"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Header"
    [(visible)]="deleteImageDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="content">
      <h1>Are you sure you want to delete this Image ?</h1>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        label="Delete"
        (clickEvent)="onDeleteImage()"
        [icon]="isLoadingDelete ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>
</div>
