import { Component, Input, OnInit } from '@angular/core';
import * as atlas from 'azure-maps-control';

@Component({
  selector: 'app-jobs-map',
  standalone: true,
  templateUrl: './jobs-map.component.html',
  styleUrls: ['./jobs-map.component.scss'],
})
export class JobsMapComponent implements OnInit {
  map!: atlas.Map;
  subscriptionKey: string = 'p52YZFE20GwoQ9D5pDfik-K6cy9lspUXh2j2MzZFuCE';
  datasource!: atlas.source.DataSource;
  @Input() jobs: any;

  async ngOnInit(): Promise<void> {
    this.initialMap();
  }

  private initialMap(): void {
    this.map = new atlas.Map('jobsMap', {
      center: [133.7751, -25.2744],
      zoom: 4,
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: this.subscriptionKey,
      },
    });

    this.map.events.add('ready', () => {
      this.datasource = new atlas.source.DataSource();
      this.map.sources.add(this.datasource);
      this.addMarkers();
    });
  }

  addMarkers(): void {
    const initialMarker = this.jobs[0]?.preciseLocation;
    if (initialMarker) this.search(initialMarker);

    this.jobs.forEach((job: any) => {
      const [latitude, longitude] = job.preciseLocation
        .split(',')
        .map((coord: any) => parseFloat(coord.trim()));
      const position: atlas.data.Position = [longitude, latitude];
      this.addMarker(position, job.jobName);
    });
  }

  private addMarker(position: atlas.data.Position, jobName: string): void {
    const markerContent = document.createElement('div');

    markerContent.innerHTML = `
      <div style="text-align: center;">
        <div class="marker-job-name">
          ${jobName}
        </div>
        <div style="margin-top: 5px;">
          <img src="/assets/location-icon-red.svg" alt="marker" style="width: 20px; height: 20px;">
        </div>
      </div>
    `;

    const marker = new atlas.HtmlMarker({
      position: position,
      htmlContent: markerContent,
      draggable: false,
    });

    this.map.markers.add(marker);
  }

  search(data: any): void {
    const location = data.split(',').map(Number).reverse();

    this.map.setCamera({
      center: location,
      zoom: 15,
    });
  }
}
